import { Stack } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useState } from 'react';

type DateRangeEditProps = {
  readonly start: Date | undefined | null;
  readonly end: Date | undefined | null;
  readonly onChange: (index: number, start: Date | null, end: Date | null) => void;
  readonly autoArrange?: boolean;
  readonly idx: number;
};

export const TimeRangeEdit: React.FC<DateRangeEditProps> = (props: DateRangeEditProps) => {
  const [start, setStart] = useState<Date | null>(props.start ?? null);
  const [end, setEnd] = useState<Date | null>(props.end ?? null);

  const arrangeDates = (startArrange: Date | null, endArrange: Date | null): [Date | null, Date | null] => {
    if (!props.autoArrange) {
      return [startArrange, endArrange];
    }

    if (!startArrange && !endArrange) {
      return [null, null];
    }

    if (!startArrange) {
      return [null, endArrange];
    }

    if (!endArrange) {
      return [startArrange, null];
    }

    if (startArrange > endArrange) {
      return [endArrange, startArrange];
    } else {
      return [startArrange, endArrange];
    }
  };

  const handleDateChange = (newValue: Date | null, startOrEnd: 'start' | 'end'): void => {
    if (startOrEnd === 'start') {
      setStart(newValue);
    } else {
      setEnd(newValue);
    }
  };

  const handleBlur = (): void => {
    const [arrangedStart, arrangedEnd] = arrangeDates(start, end);
    props.onChange(props.idx, arrangedStart, arrangedEnd);
  };

  return (
    <Stack direction="row" spacing={2}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimeField size="small" label="Start" value={start} format="HH:mm" onChange={(newValue) => handleDateChange(newValue, 'start')} onBlur={handleBlur} />
        <TimeField label="Ende" size="small" value={end} format="HH:mm" onChange={(newValue) => handleDateChange(newValue, 'end')} onBlur={handleBlur} />
      </LocalizationProvider>
    </Stack>
  );
};
