import { content } from './EditButtonGroup.content';
import { Cancel, Edit, Save } from '@mui/icons-material';
import { Button, IconButton, Stack } from '@mui/material';
import { type ReactNode } from 'react';

type EditButtonGroupProps = {
  readonly isEditMode: boolean;
  readonly setEditMode: (bool: boolean) => void;
  readonly onSave: () => void;
  readonly onCancel?: () => void;
  readonly disabledOn?: () => boolean;
  readonly buttonText?: {
    cancel: string;
    save: string;
  };
  readonly children?: ReactNode;
};

export const EditButtonGroup: React.FC<EditButtonGroupProps> = (props: EditButtonGroupProps) => {
  const { isEditMode, setEditMode, onSave, onCancel, disabledOn, buttonText } = props;

  return (
    <Stack direction="row" sx={{ width: '100%', textAlign: 'right', height: 'fit-content' }} spacing={1} justifyContent="end">
      {isEditMode ? (
        <>
          <Button
            onClick={() => {
              setEditMode(false);
              if (onCancel) {
                onCancel();
              }
            }}
            size="small"
            startIcon={<Cancel />}
            color="warning"
            sx={{ borderRadius: 1 }}
          >
            {buttonText?.cancel ?? content.ABBRECHEN}
          </Button>
          {props.children}
          <Button
            startIcon={<Save />}
            size="small"
            onClick={() => {
              onSave();
              setEditMode(false);
            }}
            color="primary"
            variant="contained"
            sx={{ borderRadius: 1, boxShadow: 1 }}
            disabled={disabledOn ? disabledOn() : false}
          >
            {buttonText?.save ?? content.SPEICHERN}
          </Button>
        </>
      ) : (
        <IconButton size="small" onClick={() => setEditMode(true)}>
          <Edit />
        </IconButton>
      )}
    </Stack>
  );
};
